<template lang="pug">
.avocado-page
  section.about-hero
    .hero-body.responsive-section
      .title-cta
        h1.title.is-spaced What's your net worth in Avocados?
        h2.subtitle
          | Introducing the Australian Avocado Index (AAI).
          | Find out how many Avos you've sacrificed
          | to get to where you are today.
        .buttons.are-medium
          router-link.button(:to="{name: 'calculator'}")
            strong Calculate now
      img.hero-illustration(src="@/assets/img/avo-chart.svg")

  section.container.section
    .columns
      .column.is-8.is-offset-2.has-text-centered
        h2.title Wait, what?
        br
        p.subtitle
          | Millenials' love of Avocados is world renowned. The media
          | loves to talk about how if we all just ate a few less smashed avos, we'd
          | be well on our way to financial freedom.
        p.subtitle
          span.highlight-text But just how many avocados are we missing out on?
          | Now, with nutworth you can answer this crucial question and measure your
          | worth in avocados.
        p.subtitle
          | Or anything really, nutworth is awesome like that.
        p.subtitle But avocados are fun. And delicious.
  section.container.section
    .columns
      .column.is-8.is-offset-2.has-text-centered
        h2.title Avocado pricing data since 2009
        p.subtitle
          | with regular updates from Australian Retail.
    .columns
      .column.is-12
        .card
          .card-content.content.chart
            b-loading(:is-full-page="false" :active.sync="chartLoading" :can-cancel="false")
            simple-line(:data='chartData' :fill='false' currency='AUD' granularity='month' label='Australian Avocado Index' color='#43ae43' v-if="chartData && !chartError" chart-id="aai-chart")
            b-message(title="Error" type="is-warning" v-if="chartError") {{chartError}}

  section.hero.is-primary.top-offset-half#currency-section
    .hero-body
      .container
        .columns
          .column.is-8.is-offset-2.has-text-centered
            h2.title
              | Track your assets in over 300 currencies.
            p.subtitle
              | View your net worth in the currency or fruit of your choice.
        .columns
          .column.is-8.is-offset-2
            .card
              .card-content
                b-table(:data="units" :columns="unitColumns" :per-page="5")
                  template(slot="footer")
                    .has-text-right + {{unitTypeCount - 5}} more
  section.container.section
    .columns
      .column.is-8.is-offset-2.has-text-centered
        h2.title Share your Avo-wealth
        p.subtitle Humblebrag about hitting 10,000 avocados.
    .columns
      .column.is-12
        .card
          .card-content.content.chart
            simple-line(:data='avoRise' :fill='true' currency='🥑AAI' granularity='month' label='Net Worth' color='#43ae43' chart-id="demo-chart")
  section.section
    .columns
      .column.is-8.is-offset-2.has-text-centered
        h2.title Give us a try
        p.subtitle Net worth tracking. How you want it.
        br
        router-link.button.is-primary.is-large(:to="{name: 'sign_up'}")
          strong Get started for free
  about-footer
</template>

<script>
import dayjs from '@/dayjs'
import firestore from '@/firebase/async-firestore'
import AboutSectionPlans from '@/components/AboutSectionPlans'
import LoadingWrapper from '@/components/LoadingWrapper'
import SimpleLine from '@/components/charts/SimpleLine'
import AboutFooter from '@/components/AboutFooter'

export default {
  name: 'AboutAvocados',
  components: {
    AboutSectionPlans,
    AboutFooter,
    SimpleLine,
    LoadingWrapper
  },
  data() {
    return {
      units: [
        { symbol: 'USD', name: 'United States Dollar', type: 'Fiat' },
        { symbol: 'AUD', name: 'Australian Dollar', type: 'Fiat' },
        { symbol: 'BTC', name: 'Bitcoin', type: 'Crypto' },
        { symbol: 'ETH', name: 'Ethereum', type: 'Crypto' },
        { symbol: 'AAI', name: 'Australian Avocado Index', type: 'Misc' }
      ],
      unitColumns: [
        { field: 'symbol', label: 'Code', width: '100' },
        { field: 'name', label: 'Name' },
        { field: 'type', label: 'Type', width: '100' }
      ],
      unitTypeCount: 332,
      chartLoading: true,
      chartData: null,
      chartError: null
    }
  },
  computed: {
    avoRise() {
      let date = dayjs()
        .startOf('month')
        .subtract(24, 'month')
      const data = []
      for (let i = 0; i < 24; ++i) {
        const fuzz = 1000 * Math.random() - 500
        data.push({
          t: date.toDate(),
          y: 1000 + Math.round(i * (10000 / 24) + fuzz)
        })
        date = date.add(1, 'month')
      }
      return data
    }
  },
  mounted() {
    this.loadAvoChart()
  },
  methods: {
    async loadAvoChart() {
      try {
        this.chartLoading = true
        this.chartError = null
        const db = await firestore()
        const snap = await db
          .collection('charts')
          .doc('🥑AAI')
          .get()
        const { data } = snap.data()
        this.chartData = data.map(({ t, y }) => {
          return { t: t.toDate(), y }
        })
      } catch (error) {
        console.error(error)
        this.chartError = 'Could not load chart ☹'
      }
      this.chartLoading = false
    }
  },
  metaInfo: {
    title: 'Australian Avocado Index (AAI)',
    meta: [
      {
        name: 'description',
        content: `Finally, an answer to the burning question: how many avocados am I worth?`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.chart
  position: relative
.highlight-text
  background: #b2e4f3
  padding: 0 0.25rem
.avocado-page
  max-width: 100%
  overflow-x: hidden
.buttons
  display: flex
  justify-content: center
  margin-top: 3rem
.about-hero
  position: relative
  min-height: 80vh
  display: flex
  align-items: center
  justify-content: center
  &:before
    position: absolute
    content: " "
    top: -10%
    left: 0
    height: 100%
    max-height: 95vh
    width: 100%
    background: linear-gradient(to right, #3e895d, 20%, #176d40) #176d40
    //border-radius: 0 0 8rem 8rem
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
    transform: skewY(-3deg)
// Disable the gradient on firefox, because it breaks - note this is v-deep
@-moz-document url-prefix()
  .about-hero:before
    background: #176d40 !important
.slant-bg
  position: relative
  padding: 3rem 0
  .responsive-section
    position: relative
    z-index: 2
    color: white
  h1, h2, h3
    color: white
  &:before
    position: absolute
    content: " "
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    background: linear-gradient(to right, #3e895d, 20%, #176d40) #176d40
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
.responsive-section
  max-width: min(100%, 1300px)
  margin: 3rem auto
  padding: 0 2rem
  flex-wrap: wrap
.hero-body
  position: relative
  z-index: 1
  display: flex
  justify-content: space-between
  align-items: center
  h1, h2
    color: white
  h2
    line-height: 2rem
.currency-list
  .responsive-section
    display: flex
    flex-direction: column
    align-items: center
  .b-table
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0,0,0,.12)
    border-radius: 0.5rem
    width: 800px
.hero-illustration
  display: none
  flex-grow: 1
  width: 40%
  max-width: 600px
  background: whitesmoke
  border-radius: 1rem
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0,0,0,.12)
  margin: 2rem 0
.info p
  max-width: 640px
  font-size: 1.2rem
.no-top-margin
  margin-top: 0 !important
.subtitle
  font-size: 1.5rem
.value-add
  .illustration
    padding: 2rem
    width: 100%
    img
      width: 100%
      max-width: 320px
    display: flex
    align-items: center
    justify-content: center
  .title
    grid-area: title
  .subtitle
    grid-area: subtitle
  .content
    grid-area: content
  .illustration
    grid-area: illustration
.cta
  position: relative
  z-index: 4
  display: flex
  flex-direction: row
  justify-content: center
  span
    padding-top: 0.5rem
.slant-bg .cta
  span
    color: white
.buttons
  justify-content: center
  .button
    font-size: 1.5rem !important

@media screen and (min-width: 700px)
  .buttons
    justify-content: flex-start
  .cta
    justify-content: flex-start
  .value-add
    display: grid
    grid-template-areas: "title illustration" "subtitle illustration" "content illustration"

@media screen and (min-width: 950px)
  .hero-illustration
    display: block
    max-width: 600px
  .title-cta
    max-width: 50%
    padding-right: 1.5rem
  .value-add
    flex-wrap: nowrap
    .illustration
      margin: 0 2rem
  .responsive-section
    margin: 6rem auto
  .slant-bg
    padding: 4rem 0
  .cta
    margin-top: -2.5rem
</style>
